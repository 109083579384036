import Navbar from './components/Navbar';
import {
  BrowserRouter,
  Routes,
  Route
} from "react-router-dom";
import Products from './pages/Products';
import Login from "./pages/Login"
import OneProductEdit from './pages/OneProductEdit';
import OneProductDelete from './pages/OneProductDelete';
import NewProduct from './pages/NewProduct';
import {useState , useEffect} from 'react';




function App() {
   

  const [isLoggedIn, setIsLoggedIn] = useState(false);

  const user = JSON.parse(localStorage.getItem('user'));
  
  useEffect(() => { 
      if (user) { 
      if (user.username === 'ck_a965784324fc499e0ca17f4e50cb89c9372d5ab1' && user.password === 'cs_cbb36642e28fa071a432d91dea328b4ed13bd4d7') {
        setIsLoggedIn(true);
      } else {
        setIsLoggedIn(false);
      }
    }
    }, [user]) 
 
   


  return (
    <>
      {
        isLoggedIn ? (
        <BrowserRouter>
          <Navbar/>
          <Routes>
              <Route path="/" element={<Products />} /> 
              <Route path="/edit/:id" element={<OneProductEdit />} />
              <Route path="/delete/:id" element={<OneProductDelete />} />
              <Route path="/products/new" element={<NewProduct />} />
          </Routes>
        </BrowserRouter>
    ) : (
      <BrowserRouter> 
        <Routes>
          <Route path="/" element={<Login/>} /> 
        </Routes>
      </BrowserRouter>
      )
    }
    </>
  );
}

export default App;
