import React, {useState , useEffect} from 'react'
import { useParams } from 'react-router-dom'
import axios from 'axios';
import "../styles/OneProductDelete.css"
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Navigate } from 'react-router-dom';

const url = 'https://gammapolymers.uz/wp-json/wc/v3/products/'
const user = JSON.parse(localStorage.getItem('user'));

if (user) {
  var username = user.username;
  var password = user.password; 
}


const OneProductDelete = () => {
  const { id } = useParams()
  const [product, setProduct] = useState({})
  const [isLoading, setIsLoading] = useState(true) 
  const [navigate, setNavigate] = useState(false)

  console.log(product)

  

  useEffect(() => { 
    axios.get(url + id, {
      params: { 
        consumer_key: username,
        consumer_secret: password
      }
    })
      .then(res => { 
        setProduct(res.data)
        console.log(res.data)
        setIsLoading(false)
      })
      .catch(err => {
        console.log(err);
      })
  }, [id])

  const deleteProduct = (e) => {
    e.preventDefault() 

    axios.delete(url + id, {
        params: { 
          consumer_key: username,
          consumer_secret: password
         }
      })
      .then(res => { 
        toast.success('Product deleted successfully!', {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        setNavigate(true)
      })
      .catch(err => {
        toast.error('Something went wrong!', {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      })
  }

  if (navigate) {
    return <Navigate to="/" />
  }

  return (
    <>
      {
        isLoading ? (
          <div className='loading-container'>Loading...</div>
        ) : (
            <div className='product-delete'> 
              <div className="one-image">
                <img src={product.images[0].src} alt="" />
                <ToastContainer/>
              </div>
              <div className="one-info">
                
                <div className="one-info-title">
                  <h1><span>Name:</span> {product.name}</h1>
                  <p><span>Price:</span> {product.price}</p>
                  <p><span>Status:</span> {product.status}</p>
                  <p><span>Description:</span> {product.description}</p> 
                </div> 
                <div className='buttons'>
                  <a target={'blank'} className='permalink' href={product.permalink}>View product</a> 
                  <button onClick={deleteProduct}>Delete product</button>
                </div>
              </div>
          </div>
        )
      }
    </>
  )
}

export default OneProductDelete