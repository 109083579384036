import React, {useState } from 'react'
import axios from 'axios';
import '../styles/NewProduct.css'
import { Navigate } from 'react-router-dom';

const url = 'https://gammapolymers.uz/wp-json/wc/v3/products/'
const user = JSON.parse(localStorage.getItem('user'));

if (user) {
  var username = user.username;
  var password = user.password; 
}

const NewProduct = () => {
  const [images, setImages] = useState('');
  const [name, setName] = useState('');
  const [regular_price, setRegular_price] = useState(''); 
  const [status, setStatus] = useState('');
  const [description, setDescription] = useState('');
  const [short_description, setShort_description] = useState('');
  const [navigate, setNavigate] = useState('');


  const handleSubmit = (e) => {
    e.preventDefault();
    axios.post(url, {
      
        "name": name,
        "type": "simple",
        "regular_price": regular_price,
        "description": description,
        "categories": [
          {
            "id": 9
          },
          {
            "id": 14
          }
        ],
        "images": [
          {
            "src":images 
          }
        ]
      
    }, {
      params: {
        consumer_key: username,
        consumer_secret: password,
      }
    })
      .then(res => {
        console.log(res);
        setNavigate(true);
      })
      .catch(err => {
        console.log(err);
      })
    
  }

  if (navigate) {
    return <Navigate to="/" />
  }


  return (
    <div className='newproduct-container'>
      
      <form className="new-form">
        <div className="form-group">
          <label htmlFor="image">Image Link</label>
          <input
            type="url"
            className="form-control"
            id="image"
            placeholder="Enter image Link"
            value={images}
            onChange={e => setImages(e.target.value)}
          />
        </div>
        <div className="form-group">
          <label htmlFor="name">Name</label>
          <input
            required
            type="text"
            className="form-control"
            id="name"
            placeholder="Enter name"
            value={name}
            onChange={e => setName(e.target.value)}
          />
        </div>
        <div className="form-group">
          <label htmlFor="price">Price</label>
          <input
            required
            type="text"
            className="form-control"
            id="price"
            placeholder="Enter price" 
            value={regular_price}
            onChange={e => setRegular_price(e.target.value)} 
            />
        </div>
        <div className="form-group">
          <label htmlFor="status">Status</label>
          <select
            className="form-control"
            id="status"
            value={status}
            onChange={e => setStatus(e.target.value)}
          >
            <option>Select</option>
            <option>Publish</option>
            <option>Draft</option>
          </select>
        </div>
        <div className="form-group">
          <label htmlFor="description">Description</label>
          <input
            type="text"
            className="form-control"
            id="description"
            placeholder="Enter description"
            value={description}
            onChange={e => setDescription(e.target.value)}
          />
        </div>
        <div className="form-group">
          <label htmlFor="short_description">Short description</label>
          <input
            type="text"
            className="form-control"
            id="short_description"
            placeholder="Enter short description"
            value={short_description}
            onChange={e => setShort_description(e.target.value)}
          />
        </div>
        <button onClick={handleSubmit} className="new-btn">
          Add Product
        </button>
      </form>
     
    </div>
  )
}

export default NewProduct