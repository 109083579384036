import React, {useState, useEffect } from 'react'
import axios from 'axios';
import "../styles/Products.css" 
import { MdEdit } from 'react-icons/md';
import {BsTrashFill} from 'react-icons/bs';
import { Link } from 'react-router-dom';

const url = 'https://gammapolymers.uz/wp-json/wc/v3/products/'
const user = JSON.parse(localStorage.getItem('user'));

if (user) {
  var username = user.username;
  var password = user.password; 
}



const Products = () => { 

  const [products, setProducts] = useState([]);
  console.log(products);

  useEffect(() => {  
    axios.get(url, {
      params: { 
        consumer_key: username,
        consumer_secret: password,
        per_page: 100
      }
    })
      .then(res => { 
        setProducts(res.data);
      })
      .catch(err => {
        console.log(err);
      })
  }, [])

  


  


  return (
    <div className='product-container'>
      <div className="nav-product">
        <h1>Products</h1>
        <Link to="/products/new" className="new-add-btn">Add Product</Link>
       </div>
      <div className="product-header">
       
      <div className="product">
        <div className='product-image-header'>Image</div> 
        <h3 className='product-name-header'>Name</h3>
        <p className='product-price-header'>Price</p> 
          <p className='product-status-header'>Status</p>
          <p className='product-setting'>Setting</p>
      </div>
      </div>
      <div className="products">
        {products.map(product => (
          <div className={product.status === "publish" ? 'product' : 'product draft' } key={product.id}>
            <div className='product-image'>
              <img src={product.images[0].src} alt=""/>
            </div> 
            <a href={product.permalink} className='product-name'>{product.name.substring(0,120)}...</a>
            <p className='product-price'>{product.price} UZS</p> 
            <p className='product-status'>{product.status}</p>
            <p className='product-setting'>
              
              <Link className='edit' to={`/edit/${product.id}`}> 
                  <MdEdit/> 
              </Link> 
              <Link  className="trash" to={`/delete/${product.id}`}>
                <BsTrashFill/>
              </Link>
            </p>
          </div>
        ))}
       </div>
    </div>
  )
}

export default Products