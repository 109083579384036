import React,{useState} from 'react'
import '../styles/Login.css'

const Login = () => {

  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')

  const defaultUsername = 'ck_a965784324fc499e0ca17f4e50cb89c9372d5ab1'
  const defaultPassword = 'cs_cbb36642e28fa071a432d91dea328b4ed13bd4d7'

  const handleSubmit = (e) => {
    e.preventDefault()
    if (username === defaultUsername && password === defaultPassword) {
      localStorage.setItem('user', JSON.stringify({ username, password }))
      window.location.reload()
    } else {
      alert('Invalid username or password')
    }
  }

  return (
    <div>
      <form className="login-form">
        <h1>Login</h1>
        <div className="form-group">
          <label htmlFor="username">Username</label>
          <input
            type="text"
            className="form-control"
            id="username"
            placeholder="Enter username"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
          />
        </div>
        <div className="form-group">
          <label htmlFor="password">Password</label>
          <input
            type="password"
            className="form-control"
            id="password"
            placeholder="Enter password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
        </div>
        <button onClick={handleSubmit} className="btn-login">Login</button>

      </form>
    </div>
  )
}

export default Login