import React, {useState , useEffect} from 'react'
import { useParams } from 'react-router-dom'
import axios from 'axios';
import "../styles/OneProductedit.css"
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Navigate } from 'react-router-dom';

const url = 'https://gammapolymers.uz/wp-json/wc/v3/products/'
const user = JSON.parse(localStorage.getItem('user'));

if (user) {
  var username = user.username;
  var password = user.password; 
}


const OneProductEdit = () => {
  const { id } = useParams()
  const [product, setProduct] = useState({})
  const [isLoading, setIsLoading] = useState(true) 
  const [name, setName] = useState('')
  const [price, setPrice] = useState('')
  const [status, setStatus] = useState('')
  const [description, setDescription] = useState('') 
  const [short_description, setShort_description] = useState('')
  const [navigate, setNavigate] = useState(false)

  console.log(product)

  

  useEffect(() => { 
    axios.get(url + id, {
      params: { 
        consumer_key: username,
        consumer_secret: password
      }
    })
      .then(res => { 
        setProduct(res.data)
        console.log(res.data)
        setIsLoading(false)
      })
      .catch(err => {
        console.log(err);
      })
  }, [id])

  const saveChanges = (e) => {
    e.preventDefault()

    setName(product.name)
    setPrice(product.price)
    setStatus(product.status)
    setDescription(product.description)
    setShort_description(product.short_description)


    axios.put(url + id, 
      {
        name,
        regular_price: price,
        status,
        description,
        short_description 
      }
      ,{
        params: { 
          consumer_key: username,
          consumer_secret: password
         }
      })
      .then(res => { 
        toast.success('Product updated successfully!', {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        setNavigate(true)
      })
      .catch(err => {
        toast.error('Something went wrong!', {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      })
  }

  if (navigate) {
    return <Navigate to={'/'} />
  }

  return (
    <>
      {
        isLoading ? (
          <div className='loading-container'>Loading...</div>
        ) : (
            <div className='one-product'> 
              <div className="one-image">
                <img src={product.images[0].src} alt="" />
                <ToastContainer/>
              </div>
              <div className="one-info">
                <form  className="change-info">
                  <div className="form-group">
                    <label htmlFor="name">Name</label>
                    <input
                      type="text" className="form-control" id="name"
                      value={product.name}
                      onChange={(e) => setProduct({ ...product, name: e.target.value })}
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="price">Price</label>
                    <input
                      type="text" className="form-control"
                      id="price" value={product.price} 
                      onChange={(e) => setProduct({ ...product, price: e.target.value })} 
                      />
                  </div>
                  <div className="form-group">
                    <label htmlFor="status">Status</label>
                    <select
                      className="form-control"
                      id="status"
                      value={product.status}
                      onChange={(e) => setProduct({ ...product, status: e.target.value })}
                    > 
                      <option>publish</option>
                      <option>draft</option>
                    </select>
                  </div>
                  <div className="form-group">
                    <label htmlFor="description">Description</label>
                    <textarea
                      className="form-control"
                      id="description" 
                      value={product.description}
                      onChange={(e) => setProduct({ ...product, description: e.target.value })}
                    >{product.description}</textarea>
                  </div>
                  <div className="form-group">
                    <label htmlFor="short_description">Short description</label>
                    <textarea
                      className="form-control"
                      id="short_description" 
                      value={product.short_description}
                      onChange={(e) => setProduct({ ...product, short_description: e.target.value })}
                    >{product.short_description}</textarea>
                  </div>
                  <button className='one-submit' onClick={saveChanges}>Save changes</button>
                </form>
              </div>
          </div>
        )
      }
    </>
  )
}

export default OneProductEdit